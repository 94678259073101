<template>
  <ul>
    <!-- {{product}} -->
    <!-- <li class="quick_button">
      <a href="#">
        {{ json_product.ID }}
      </a>
    </li> -->
    <li class="add_to_cart">
      <a href="#" @click.prevent="addToCart()" title="Dodaj u korpu">
        <span class="lnr lnr-cart"></span>
      </a>
    </li>

    <li class="wishlist">
      <a
        v-if="!isInWishList"
        href="#"
        @click.prevent="addToWishList()"
        title="Dodaj u listu želja"
      >
        <span class="lnr lnr-heart"></span>
      </a>
      <a class="no-link" v-else title="U listi želja">
        <span class="lnr lnr-checkmark-circle"></span>
      </a>
    </li>
  </ul>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ActionLinks",
  props: ["product_id"],
  data() {
    return {
      amount: 1,
    };
  },
  methods: {
    addToWishList() {
      this.$store
        .dispatch("wish_list/addToWishList", {
          id: parseInt(this.product_id),
        })
        .then(() => {
          // this.isInWishList = true
          this.$toasted.show("Proizvod je dodat Listu želja!!", {
            iconPack: "custom-class",
            theme: "bubble",
            position: "top-center",
            duration: 5000,
            icon: {
              name: "lnr lnr-cart",
              after: false,
            },
            action: {
              icon: {
                name: "fal fa-times",
                after: true,
              },
              onClick: (e, toastObject) => {
                toastObject.goAway(0);
              },
            },
          });
        });
    },
    addToCart() {
      this.$store
        .dispatch("cart/addToCart", {
          id: parseInt(this.product_id),
          amount: parseInt(this.amount),
        })
        .then(() => {
          this.$toasted.show("Proizvod je dodat u korpu !!", {
            iconPack: "custom-class",
            theme: "bubble",
            position: "top-center",
            duration: 5000,
            icon: {
              name: "lnr lnr-cart",
              after: false,
            },
            action: {
              icon: {
                name: "fal fa-times",
                after: true,
              },
              onClick: (e, toastObject) => {
                toastObject.goAway(0);
              },
            },
          });
        });
    },
    checkIsInWishList(id) {
      if (this.wishList.length>0) {
        return this.wishList.some(function(el) {
          return el.ID === id;
        });
      } else {
        return false;
      }
    },
  },
  computed: {
    // getWishList

    ...mapGetters({
      wishList: "wish_list/getWishList",
    }),
    // json_product: function() {
    //   // console.log('this.product :>> ', this.product);
    //   return JSON.parse(this.product);
    // },
    isInWishList: {
      get: function() {
        return this.checkIsInWishList(this.product_id);
      },
      set: function(newValue) {
        return newValue;
      },
    },
  },
  mounted() {
    // console.log('this.wishList :>> ', this.wishList);
  },
};
</script>

<style scoped></style>
