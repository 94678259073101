var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ul", [
    _c("li", { staticClass: "add_to_cart" }, [
      _c(
        "a",
        {
          attrs: { href: "#", title: "Dodaj u korpu" },
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.addToCart()
            }
          }
        },
        [_c("span", { staticClass: "lnr lnr-cart" })]
      )
    ]),
    _vm._v(" "),
    _c("li", { staticClass: "wishlist" }, [
      !_vm.isInWishList
        ? _c(
            "a",
            {
              attrs: { href: "#", title: "Dodaj u listu želja" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.addToWishList()
                }
              }
            },
            [_c("span", { staticClass: "lnr lnr-heart" })]
          )
        : _c(
            "a",
            { staticClass: "no-link", attrs: { title: "U listi želja" } },
            [_c("span", { staticClass: "lnr lnr-checkmark-circle" })]
          )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }