<template>
  <div class="remove-from-wishlist">
    <a
      class="remove-from-wishlist__link"
      href="#"
      @click.prevent="removeFromWishList()"
      title="Izbaci iz liste želja"
    >
      <span class="lnr lnr-cross-circle"></span>
    </a>
  </div>
</template>

<script>
export default {
  name: "RemoveFromWishList",
  props:['product_id'],
  methods:{
      removeFromWishList(){
          console.log('product_id to remove :>> ', this.product_id);
           this.$store
        .dispatch("wish_list/removeFromWishList", {
          id: this.product_id,
        })
        .then(() => {
          // this.isInWishList = true
         const productEl =  document.getElementById(`wish_list_product_col_${this.product_id}`)
         productEl.parentElement.removeChild(productEl);

        // const tooltip = document.getElementsByClassName('tooltip');
        // console.log('tooltip :>> ', tooltip);
        document.querySelectorAll('.tooltip').forEach(e => e.remove());
// tooltip.parentElement.removeChild(tooltip);

       

          this.$toasted.show("Proizvod je izbačen iz Listu želja!!", {
            iconPack: "custom-class",
            theme: "bubble",
            position: "top-center",
            duration: 5000,
            icon: {
              name: "lnr lnr-cart",
              after: false,
            },
            action: {
              icon: {
                name: "fal fa-times",
                after: true,
              },
              onClick: (e, toastObject) => {
                toastObject.goAway(0);
              },
            },
          });
        });
      }
  }
};
</script>

<style></style>
