import axios from "axios";

/**
 * converts object to FormData
 * @param {object} postData
 * @returns FormData
 */
function convertToFormData(postData) {
  var formData = new FormData();
  if (Number.isInteger(postData.product_id_cart)) {
    for (var key in postData) {
      formData.append(key, postData[key]);
    }
    return formData;
  } else {
    throw new Error("id is not int!");
  }
}

function calculateCartTotal(cart) {
  let total = 0;
  if (cart && Array.isArray(cart)) {
    const sum = cart
      .map((item) => (item.price / 100) * item.quantity)
      .reduce((prev, curr) => prev + curr, 0);
    total = sum;
  } else {
    total = 0;
  }
  return total;
}

const cart = {
  namespaced: true,

  state: {
    cart: [],
    cartTotal: 0,
  },
  getters: {
    getCart(state) {
      return state.cart;
    },
    getCartTotalQuantity(state) {
      if (state.cart) {
        return state.cart.length;
      } else {
        return 0;
      }
    },
    getCartTotalAmount(state) {
      return state.cartTotal;
    },
  },
  mutations: {
    SET_CART(state, data) {
      state.cart = data;
    },
    SET_CART_TOTAL(state, data) {
      state.cartTotal = data;
    },
  },
  actions: {
    loadCart({ commit }) {
      commit("SET_CART", window.CART_CONTENT_ARRAY);
      commit("SET_CART_TOTAL", calculateCartTotal(window.CART_CONTENT_ARRAY));
    },

    removeFromCart({ commit }, { id }) {
      const postData = {
        product_id_cart: parseInt(id),
      };

      axios
        .post(
          window.urlMain + "cart-sesija-remove.php",
          convertToFormData(postData)
        )
        .then((res) => {
          commit("SET_CART", res.data.cart_json);
          commit("SET_CART_TOTAL", calculateCartTotal(res.data.cart_json));
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // addToCart({ commit }, { product, amount }) {
    addToCart({ commit }, { id, amount }) {
      console.log("id: ", id);
      const postData = {
        product_id_cart: parseInt(id),
        product_cart_quantity: parseInt(amount),
      };

      axios
        .post(window.urlMain + "cart-sesija.php", convertToFormData(postData))
        .then((res) => {
          commit("SET_CART", res.data.cart_json);
          commit("SET_CART_TOTAL", calculateCartTotal(res.data.cart_json));
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};

export default cart;
