<template>
  <div class="cart_gallery">
    <div class="cart_close">
      <div class="cart_text">
        <h3>Korpa</h3>
      </div>
      <div class="mini_cart_close">
        <a href="javascript:void(0)"><i class="icon-x"></i></a>
      </div>
    </div>
    <div class="mini-cart-items">
      <mini-cart-item :item="item" v-for="item in cart" :key="item.ID" />
    </div>

    <div class="mini_cart_table">
      <div class="cart_table_border">
        <div class="cart_total mt-10">
          <span>Ukupno:</span>
          <span class="price">
            {{ cartTotalAmount | currency }}
            <span class="currency-simbol">rsd</span>
          </span>
        </div>
      </div>
    </div>
    <div class="mini_cart_footer">
      <div class="cart_button">
        <a :href="urlMain + 'korpa'"
          ><i class="fa fa-shopping-cart"></i> Korpa</a
        >
      </div>
      <div class="cart_button">
        <a :href="urlMain + 'porucivanje'"
          ><i class="fa fa-sign-in"></i> Poručivanje</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import MiniCartItem from "./MiniCartItem";
import { mapGetters } from "vuex";
export default {
  name: "MiniCart",
  data() {
    return {
      urlMain: window.urlMain,
    };
  },
  components: { MiniCartItem },
  computed: mapGetters({
    cart: "cart/getCart",
    cartTotalAmount: "cart/getCartTotalAmount",
  }),
};
</script>

<style>
.mini-cart-items{
  height: calc(100vh - 270px);
  overflow: auto;
}
</style>
