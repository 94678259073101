import Vue from 'vue';
import Vuex from 'vuex';
// import posts from './modules/posts'
import products from './modules/products';
import cart from './modules/cart';
import wish_list from './modules/wish_list';
Vue.use(Vuex);

export default new Vuex.Store({

    modules: {
        // posts,
        products,
        cart,
        wish_list
    }
})
