<template>
  <a :href="urlMain + 'lista-zelja'">
    <span class="lnr lnr-heart"></span>
    <span class="item_count">{{ wishListQuantity }}</span>
  </a>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "HeaderWishList",
  data() {
    return {
      urlMain: window.urlMain,
    };
  },
  computed: mapGetters({
    wishListQuantity: "wish_list/getWishListQuantity",
  }),
};
</script>

<style></style>
