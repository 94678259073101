var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cart_item" }, [
    _c("div", { staticClass: "cart_img" }, [
      _c("a", { attrs: { href: "#" } }, [
        _c("img", { attrs: { src: _vm.itemImageURL, alt: _vm.itemTitle } })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "cart_info" }, [
      _c("a", { staticClass: "cart_item__title", attrs: { href: "#" } }, [
        _vm._v(_vm._s(_vm.itemTitle))
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v("\n      " + _vm._s(_vm.itemQuantity) + " x\n      "),
        _c("span", [
          _vm._v(
            "\n        " + _vm._s(_vm._f("currency")(_vm.itemPrice)) + " "
          ),
          _c("span", { staticClass: "currency-simbol" }, [_vm._v("rsd")])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "cart_remove" }, [
      _c("a", { attrs: { href: "#" }, on: { click: _vm.removeFromCart } }, [
        _c("i", { staticClass: "icon-x" })
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }