var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      staticClass: "header-cart header_cart_btn",
      attrs: { href: "javascript:void(0)" }
    },
    [
      _c("span", { staticClass: "lnr lnr-cart" }),
      _vm._v(" "),
      _c("span", { staticClass: "item_count" }, [
        _vm._v(_vm._s(_vm.getCartTotalQuantity))
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }