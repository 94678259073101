<template>
  <tr>
    <td class="product_remove">
      <a href="#" @click.prevent="removeFromCart"
        ><i class="fa fa-trash-o"></i
      ></a>
    </td>
    <td class="product_thumb">
      <a href="#"><img :src="itemImageURL" alt=""/></a>
    </td>
    <td class="product_name">
      <a href="#">{{ itemTitle }}</a>
    </td>
    <td class="product-price">
      {{ itemPrice | currency }} <span class="currency-simbol">rsd</span>
    </td>
    <td class="product_quantity">
      <label>Količina</label>
      <input min="1" max="100" v-model="itemQuantity" type="number" />
    </td>
    <td class="product_total">
      {{ itemTotal | currency }} <span class="currency-simbol">rsd</span>
    </td>
  </tr>
</template>

<script>
export default {
  name: "CartRow",
  props: ["item"],
  data() {
    return {
      itemQuantity: this.item.quantity,
    };
  },
  computed: {
    itemTitle() {
      return this.item.title;
    },
    itemPrice() {
      return this.item.price / 100;
    },
    itemPriceSale() {
      return this.item.price / 100;
    },
 
    itemImageURL() {
      return window.urlMain + "/images/products/sthumbs/" + this.item.image;
    },
    itemTotal() {
      return this.itemPrice * this.itemQuantity;
    },
    // TODO -- dodati product url u objekat korpe
    // itemURL() {
    //     // http://localhost/prodavnica/med-i-zasladivaci/manuka-med-umf-20-kiwis-honipai-250g.html
    //   return this.item.quantity;
    // },
  },
  methods: {
    removeFromCart() {
      // console.log('item :>> ', this.item);
      this.$store
        .dispatch("cart/removeFromCart", {
          product: this.item,
        })
        .then(
          (res) => {
            this.$toasted.show("Proizvod je izbačen iz korpe!!", {
              className: "removed-toast",
              iconPack: "custom-class",
              theme: "bubble",
              position: "top-center",
              duration: 5000,
              icon: {
                name: "lnr lnr-cart",
                after: false,
              },
              action: {
                icon: {
                  name: "fal fa-times",
                  after: true,
                },
                onClick: (e, toastObject) => {
                  toastObject.goAway(0);
                },
              },
            });
          }
          // this.$emit("itemTotal", 0);
        );
    },
    // updateQuantity(quantity) {
    //   this.$store.dispatch("cart/updatecartItemQuantity", {
    //     product: this.item,
    //     amount:this.itemQuantity
    //   });
    // },
  },
  watch: {
    itemQuantity(value) {
      // this.$emit("itemTotal", this.itemTotal);
      this.$store.dispatch("cart/addToCart", {
        product: this.item,
        amount:this.itemQuantity
      });
    },
  },
};
</script>

<style></style>
