import axios from "axios";
function convertToFormData(postData) {
  var formData = new FormData();
  if (Number.isInteger(postData.product_id_wishlist)) {
    for (var key in postData) {
      formData.append(key, postData[key]);
    }
    return formData;
  } else {
    throw new Error("id is not int!");
  }
}
const wish_list = {
  // wishlist-sesija.php
  namespaced: true,
  state: {
    wishlist: [],
  },
  mutations: {
    SET_WISHLIST(state, data) {
      state.wishlist = data;
    },
  },
  getters: {
    getWishList(state) {
      return state.wishlist;
    },
    getWishListQuantity(state) {
      if (state.wishlist) {
        return state.wishlist.length;
      } else {
        return 0;
      }
    },
  },
  actions: {
    loadWishList({ commit }) {
        console.log('loadWishList :>> ', window.WISHLIST_CONTENT_ARRAY);
      commit("SET_WISHLIST", window.WISHLIST_CONTENT_ARRAY);
    },
    addToWishList({ commit }, { id }) {
      const postData = {
        product_id_wishlist: parseInt(id),
      };
    //   console.log("product :>> ", product);
      axios
        .post(
          window.urlMain + "wishlist-sesija.php",
          convertToFormData(postData)
        )
        .then((res) => {
          console.log('wishlist_json :>> ', res.data.wishlist_json);
          commit("SET_WISHLIST", res.data.wishlist_json);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    removeFromWishList({ commit }, { id }){
      const postData = {
        product_id_wishlist: parseInt(id),
      };
    //   console.log("product :>> ", product);
      axios
        .post(
          window.urlMain + "wishlist-sesija-remove.php",
          convertToFormData(postData)
        )
        .then((res) => {
          console.log('wishlist_json :>> ', res.data.wishlist_json);
          commit("SET_WISHLIST", res.data.wishlist_json);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
};
export default wish_list;
