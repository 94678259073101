// https://fakestoreapi.com/products

import axios from 'axios'

const products = {
    namespaced: true,

    state: {
        products: []
    },
    mutations: {
        SET_PRODUCTS (state, data) {
            state.products = data
        }
    },
    actions: {
        loadProducts ({ commit }) {
            axios
                .get('https://fakestoreapi.com/products')
                .then(res => {
                    commit('SET_PRODUCTS', res.data)
                })
                .catch(err => {
                    console.log(err);

                })
        }
    }
}

export default products;
