window.Vue = require("vue");

import Axios from "axios";
import Toasted from "vue-toasted";
Vue.use(Toasted);

/** ====================================================================================================================
 * STORE
 =====================================================================================================================*/
import store from "./store";

import VueCurrencyFilter from "vue-currency-filter";
Vue.use(VueCurrencyFilter, {
  symbol: "",
  thousandsSeparator: ".",
  fractionCount: 2,
  fractionSeparator: ",",
  symbolPosition: "back",
  symbolSpacing: true,
  avoidEmptyDecimals: undefined,
});

/** ====================================================================================================================
 * COMPONENTS
 =====================================================================================================================*/
Vue.component(
  "example-component",
  require("./components/ExampleComponent1.vue").default
);
Vue.component("action-links", require("./components/ActionLinks.vue").default);
Vue.component("remove-from-wish-list", require("./components/RemoveFromWishList.vue").default);
Vue.component("header-cart", require("./components/HeaderCart.vue").default);
Vue.component("header-wish-list", require("./components/HeaderWishList.vue").default);
Vue.component("mini-cart", require("./components/MiniCart.vue").default);
Vue.component("cart", require("./components/Cart.vue").default);
Vue.component("single-product-action", require("./components/SingleProductAction.vue").default);

Vue.prototype.$http = Axios;


const app = new Vue({
  store: store,
  el: "#app",
  data() {
    return {
      cart: {},
    };
  },
  created() {
    // get cart on start
    this.$store.dispatch("cart/loadCart");
    this.$store.dispatch("wish_list/loadWishList");
  },
});

// new Vue({
//     store,
//     render: h => h(App)
// }).$mount('#app');
