<template>
  <div class="cart_item">
    <div class="cart_img">
      <a href="#"><img :src="itemImageURL" :alt="itemTitle"/></a>
    </div>
    <div class="cart_info">
      <a href="#" class="cart_item__title">{{ itemTitle }}</a>
      <p>
        {{ itemQuantity }} x
        <span>
          {{ itemPrice | currency }} <span class="currency-simbol">rsd</span>
        </span>
      </p>
    </div>
    <div class="cart_remove">
      <a v-on:click="removeFromCart" href="#"><i class="icon-x"></i></a>
    </div>
  </div>
</template>

<script>
export default {
  name: "MiniCartItem",
  props: ["item"],
  computed: {
    itemTitle() {
      const titleLength = 40;
      if(this.item.title){

        const ellipsis = this.item.title.length > titleLength ? "..." : "";
        return this.item.title.substring(0, titleLength) + ellipsis;
      } else {
        return 'no title'
      }
    },
    itemPrice() {
      return this.item.price / 100;
    },
    itemPriceSale() {
      return this.item.price / 100;
    },
    itemQuantity() {
      return this.item.quantity;
    },
    itemImageURL() {
      return window.urlMain + "/images/products/sthumbs/" + this.item.image;
    },
    // TODO -- dodati product url u objekat korpe
    // itemURL() {
    //     // http://localhost/prodavnica/med-i-zasladivaci/manuka-med-umf-20-kiwis-honipai-250g.html
    //   return this.item.quantity;
    // },
  },
  methods: {
    removeFromCart() {
      // console.log('item :>> ', this.item);
      this.$store
      .dispatch("cart/removeFromCart", {
        id: this.item.ID,
      }).then(
          this.$toasted.show("Proizvod je izbačen iz korpe!!", {
            className:'removed-toast',
            iconPack: "custom-class",
            theme: "bubble",
            position: "top-center",
            duration: 5000,
            icon: {
              name: "lnr lnr-cart",
              after: false,
            },
            action: {
              icon: {
                name: "fal fa-times",
                after: true,
              },
              onClick: (e, toastObject) => {
                toastObject.goAway(0);
              },
            },
          })
        );;
    },
  },
};
</script>

<style>
.cart_item__title {
  width: 200px;
}
.cart_info .currency-simbol {
  opacity: 0.5;
}
</style>
