<template>
  <div class="cart-content__wrap">
    <div class="sec-header">
      <h3 class="sec-title bio-title page-title">
        <span class="sec-title__brend">Korpa</span>
      </h3>
    </div>

    <!--shopping cart area start -->
    <div class="shopping_cart_area mt-30">
      <div class="row">
        <div class="col-12">
          <div class="table_desc">
            <div class="cart_page table-responsive">
              <table>
                <thead>
                  <tr>
                    <th class="product_remove">Izbaci</th>
                    <th class="product_thumb">Slika</th>
                    <th class="product_name">Proizvod</th>
                    <th class="product-price">Cena</th>
                    <th class="product_quantity">Kol</th>
                    <th class="product_total">Ukupno</th>
                  </tr>
                </thead>
                <tbody>
                  <cart-row :item="item" v-for="item in cart" :key="item.ID" />
                
                </tbody>
              </table>
            </div>
            <div class="cart_submit">
              <a :href="urlMain">Nastavite kupovinu</a>
            </div>
          </div>
        </div>
      </div>
      <!--coupon code area start-->
      <div class="coupon_area">
        <div class="row">
          <div class="col-lg-6 col-md-6">
            <div class="coupon_code left">
              <h3>Plaćanje / Poštarina</h3>
              <div class="coupon_inner">
                <b>Plaćanje</b>
                <p>
                  Plaćanje se vrši prilikom preuzimanja robe od dostavne službe.
                </p>
              </div>
              <div class="coupon_inner">
                <b>Poštarina</b>
                <p>
                  Cena dostave može da varira u zavisnosti od težine paketa i
                  cenovnika dostavne službe. Cenovnik dostavne službe možete
                  pogledati
                  <a
                    class="d-inline"
                    :href="urlMain + 'kako-kupovati-online/nacin-isporuke'"
                    >OVDE</a
                  >.
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-6">
            <div class="coupon_code right">
              <h3>Ukupno u korpi</h3>
              <div class="coupon_inner">
                <div class="cart_subtotal">
                  <p>Ukupno</p>
                  <p class="cart_amount">
                    {{ cartTotalAmount | currency }}
                    <span class="currency-simbol">rsd</span>
                  </p>
                </div>
                <div class="checkout_btn">
                  <a :href="urlMain + 'porucivanje'" >Poručivanje</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--coupon code area end-->
    </div>
    <!--shopping cart area end -->
  </div>
</template>

<script>
import CartRow from "./CartRow";
import { mapGetters } from "vuex";
export default {
  name: "Cart",
  data() {
    return {
      urlMain: window.urlMain,
    };
  },
  components: {
    "cart-row": CartRow,
  },
  computed: mapGetters({
    cart: "cart/getCart",
    cartTotalAmount: "cart/getCartTotalAmount",
  }),
  // methods: {
  //   itemTotalRecived(total) {
  //     console.log(total);
  //   },
  // },
};
</script>

<style></style>
