var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a", { attrs: { href: _vm.urlMain + "lista-zelja" } }, [
    _c("span", { staticClass: "lnr lnr-heart" }),
    _vm._v(" "),
    _c("span", { staticClass: "item_count" }, [
      _vm._v(_vm._s(_vm.wishListQuantity))
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }