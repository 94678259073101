<template>
  <a class="header-cart header_cart_btn" href="javascript:void(0)">
    <span class="lnr lnr-cart"></span>
    <span class="item_count">{{ getCartTotalQuantity }}</span>
  </a>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "HeaderCart",
  computed: mapGetters({
    getCartTotalQuantity: 'cart/getCartTotalQuantity'
  }),
};
</script>
