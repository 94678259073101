var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "remove-from-wishlist" }, [
    _c(
      "a",
      {
        staticClass: "remove-from-wishlist__link",
        attrs: { href: "#", title: "Izbaci iz liste želja" },
        on: {
          click: function($event) {
            $event.preventDefault()
            return _vm.removeFromWishList()
          }
        }
      },
      [_c("span", { staticClass: "lnr lnr-cross-circle" })]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }