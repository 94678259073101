var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("tr", [
    _c("td", { staticClass: "product_remove" }, [
      _c(
        "a",
        {
          attrs: { href: "#" },
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.removeFromCart($event)
            }
          }
        },
        [_c("i", { staticClass: "fa fa-trash-o" })]
      )
    ]),
    _vm._v(" "),
    _c("td", { staticClass: "product_thumb" }, [
      _c("a", { attrs: { href: "#" } }, [
        _c("img", { attrs: { src: _vm.itemImageURL, alt: "" } })
      ])
    ]),
    _vm._v(" "),
    _c("td", { staticClass: "product_name" }, [
      _c("a", { attrs: { href: "#" } }, [_vm._v(_vm._s(_vm.itemTitle))])
    ]),
    _vm._v(" "),
    _c("td", { staticClass: "product-price" }, [
      _vm._v("\n    " + _vm._s(_vm._f("currency")(_vm.itemPrice)) + " "),
      _c("span", { staticClass: "currency-simbol" }, [_vm._v("rsd")])
    ]),
    _vm._v(" "),
    _c("td", { staticClass: "product_quantity" }, [
      _c("label", [_vm._v("Količina")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.itemQuantity,
            expression: "itemQuantity"
          }
        ],
        attrs: { min: "1", max: "100", type: "number" },
        domProps: { value: _vm.itemQuantity },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.itemQuantity = $event.target.value
          }
        }
      })
    ]),
    _vm._v(" "),
    _c("td", { staticClass: "product_total" }, [
      _vm._v("\n    " + _vm._s(_vm._f("currency")(_vm.itemTotal)) + " "),
      _c("span", { staticClass: "currency-simbol" }, [_vm._v("rsd")])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }