var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cart-content__wrap" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "shopping_cart_area mt-30" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("div", { staticClass: "table_desc" }, [
            _c("div", { staticClass: "cart_page table-responsive" }, [
              _c("table", [
                _vm._m(1),
                _vm._v(" "),
                _c(
                  "tbody",
                  _vm._l(_vm.cart, function(item) {
                    return _c("cart-row", {
                      key: item.ID,
                      attrs: { item: item }
                    })
                  }),
                  1
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "cart_submit" }, [
              _c("a", { attrs: { href: _vm.urlMain } }, [
                _vm._v("Nastavite kupovinu")
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "coupon_area" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-lg-6 col-md-6" }, [
            _c("div", { staticClass: "coupon_code left" }, [
              _c("h3", [_vm._v("Plaćanje / Poštarina")]),
              _vm._v(" "),
              _vm._m(2),
              _vm._v(" "),
              _c("div", { staticClass: "coupon_inner" }, [
                _c("b", [_vm._v("Poštarina")]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "\n                Cena dostave može da varira u zavisnosti od težine paketa i\n                cenovnika dostavne službe. Cenovnik dostavne službe možete\n                pogledati\n                "
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "d-inline",
                      attrs: {
                        href:
                          _vm.urlMain + "kako-kupovati-online/nacin-isporuke"
                      }
                    },
                    [_vm._v("OVDE")]
                  ),
                  _vm._v(".\n              ")
                ])
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-lg-6 col-md-6" }, [
            _c("div", { staticClass: "coupon_code right" }, [
              _c("h3", [_vm._v("Ukupno u korpi")]),
              _vm._v(" "),
              _c("div", { staticClass: "coupon_inner" }, [
                _c("div", { staticClass: "cart_subtotal" }, [
                  _c("p", [_vm._v("Ukupno")]),
                  _vm._v(" "),
                  _c("p", { staticClass: "cart_amount" }, [
                    _vm._v(
                      "\n                  " +
                        _vm._s(_vm._f("currency")(_vm.cartTotalAmount)) +
                        "\n                  "
                    ),
                    _c("span", { staticClass: "currency-simbol" }, [
                      _vm._v("rsd")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "checkout_btn" }, [
                  _c("a", { attrs: { href: _vm.urlMain + "porucivanje" } }, [
                    _vm._v("Poručivanje")
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "sec-header" }, [
      _c("h3", { staticClass: "sec-title bio-title page-title" }, [
        _c("span", { staticClass: "sec-title__brend" }, [_vm._v("Korpa")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { staticClass: "product_remove" }, [_vm._v("Izbaci")]),
        _vm._v(" "),
        _c("th", { staticClass: "product_thumb" }, [_vm._v("Slika")]),
        _vm._v(" "),
        _c("th", { staticClass: "product_name" }, [_vm._v("Proizvod")]),
        _vm._v(" "),
        _c("th", { staticClass: "product-price" }, [_vm._v("Cena")]),
        _vm._v(" "),
        _c("th", { staticClass: "product_quantity" }, [_vm._v("Kol")]),
        _vm._v(" "),
        _c("th", { staticClass: "product_total" }, [_vm._v("Ukupno")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "coupon_inner" }, [
      _c("b", [_vm._v("Plaćanje")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n                Plaćanje se vrši prilikom preuzimanja robe od dostavne službe.\n              "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }