var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "single-product-action" }, [
    _c("div", { staticClass: "product_variant quantity" }, [
      _c("label", [_vm._v("Komada")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.amount,
            expression: "amount"
          }
        ],
        attrs: { min: "1", max: "100", type: "number" },
        domProps: { value: _vm.amount },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.amount = $event.target.value
          }
        }
      }),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "button",
          attrs: { type: "submit" },
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.addToCart()
            }
          }
        },
        [_vm._v("\n      Dodaj u korpu\n    ")]
      )
    ]),
    _vm._v(" "),
    _vm._m(0)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: " product_d_action" }, [
      _c("ul", [
        _c("li", [
          _c("a", { attrs: { href: "#", title: "Add to wishlist" } }, [
            _vm._v("+ Dodaj u listu želja")
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }