<template>
<div>ExampleComponent1</div>
</template>

<script>
    export default {
        name: "ExampleComponent1"
    }
</script>

<style scoped>

</style>
