<template>
  <div class="single-product-action">
     
    <div class="product_variant quantity">
      <label>Komada</label>
      <input min="1" max="100" v-model="amount" type="number" />
      <button class="button" type="submit" @click.prevent="addToCart()">
        Dodaj u korpu
      </button>
    </div>
    <div class=" product_d_action">
      <ul>
        <li><a href="#" title="Add to wishlist">+ Dodaj u listu želja</a></li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "SingleProductAction",
  props: ["product_id"],
  data() {
    return {
      amount: 1,
    };
  },
  methods: {
    addToWishList() {
      this.$store
        .dispatch("wish_list/addToWishList", {
          id: this.product_id,
        })
        .then(() => {
          // this.isInWishList = true
          this.$toasted.show("Proizvod je dodat Listu želja!!", {
            iconPack: "custom-class",
            theme: "bubble",
            position: "top-center",
            duration: 5000,
            icon: {
              name: "lnr lnr-cart",
              after: false,
            },
            action: {
              icon: {
                name: "fal fa-times",
                after: true,
              },
              onClick: (e, toastObject) => {
                toastObject.goAway(0);
              },
            },
          });
        });
    },
    addToCart() {
      this.$store
        .dispatch("cart/addToCart", {
          id: this.product_id,
          amount: this.amount,
        })
        .then(() => {
          this.$toasted.show("Proizvod je dodat u korpu !!", {
            iconPack: "custom-class",
            theme: "bubble",
            position: "top-center",
            duration: 5000,
            icon: {
              name: "lnr lnr-cart",
              after: false,
            },
            action: {
              icon: {
                name: "fal fa-times",
                after: true,
              },
              onClick: (e, toastObject) => {
                toastObject.goAway(0);
              },
            },
          });
        });
    },
  },
  computed: {
    // getWishList

    ...mapGetters({
      wishList: "wish_list/getWishList",
    }),
    // json_product: function() {
    //   return JSON.parse(this.product);
    // },
    isInWishList: {
      get: function() {
        return this.checkIsInWishList(this.product_id);
      },
      set: function(newValue) {
        return newValue;
      },
    },
  },
};
</script>

<style></style>
