var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cart_gallery" }, [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "mini-cart-items" },
      _vm._l(_vm.cart, function(item) {
        return _c("mini-cart-item", { key: item.ID, attrs: { item: item } })
      }),
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "mini_cart_table" }, [
      _c("div", { staticClass: "cart_table_border" }, [
        _c("div", { staticClass: "cart_total mt-10" }, [
          _c("span", [_vm._v("Ukupno:")]),
          _vm._v(" "),
          _c("span", { staticClass: "price" }, [
            _vm._v(
              "\n          " +
                _vm._s(_vm._f("currency")(_vm.cartTotalAmount)) +
                "\n          "
            ),
            _c("span", { staticClass: "currency-simbol" }, [_vm._v("rsd")])
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "mini_cart_footer" }, [
      _c("div", { staticClass: "cart_button" }, [
        _c("a", { attrs: { href: _vm.urlMain + "korpa" } }, [
          _c("i", { staticClass: "fa fa-shopping-cart" }),
          _vm._v(" Korpa")
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "cart_button" }, [
        _c("a", { attrs: { href: _vm.urlMain + "porucivanje" } }, [
          _c("i", { staticClass: "fa fa-sign-in" }),
          _vm._v(" Poručivanje")
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "cart_close" }, [
      _c("div", { staticClass: "cart_text" }, [_c("h3", [_vm._v("Korpa")])]),
      _vm._v(" "),
      _c("div", { staticClass: "mini_cart_close" }, [
        _c("a", { attrs: { href: "javascript:void(0)" } }, [
          _c("i", { staticClass: "icon-x" })
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }